import Error from 'next/error';
import React from 'react';

import {Layout} from '@/components/layout';

export default function NotFound() {
  return (
    <Layout title="Sidan kunde inte hittas - IPO.se">
      <Error statusCode={404} />
    </Layout>
  );
}
